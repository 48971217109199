<template>
  <b-container :class="[style['product-details-wrap'], 'mt-5 w-100 mx-0']">
    <LoadingSpinner v-if="loading" />
    <div>
      <span
        class="font-weight-bold h4"
        style="cursor:pointer;"
        @click="()=>$router.push(`/feed/${username}`)"
      >&lt; Back to profile</span>
      <b-row class="mt-4 mb-5 mx-0">
        <b-col
          lg="8"
          class="zoomerExpress d-flex"
        >
          <div>
            <div
              v-for="(value, index) in images"
              :key="index"
            >
              <video
                v-if="value.file_type ==='video' && !isVideoError"
                :id="`video${value.id}`"
                style="width:70px; border:1px solid;"
                class="my-1"
                @click="handleSelection(value.id, value.file_type)"
              >
                <source
                  :id="value.id"
                  :src="value.image"
                  @error="handleVideoError(value.id)"
                >
              </video>
              <img
                v-else-if="value.file_type ==='video' && isVideoError"
                :src="placeholderImage"
              >
              <img
                v-else
                :id="value.id"
                :src="value.image"
                alt="product-image"
                style="width:70px;border:1px solid;"
                class="my-1"
                fluid
                @click="handleSelection(value.id, value.file_type)"
                @error="handleError(value.id)"
              >
            </div>
          </div>
          <div
            v-if="zoomType ==='image'"
            class="ml-5"
          >
            <zoom-on-hover
              :img-normal="isVideoError ? placeholderImage : zoomImage"
              :scale="0"
            />
          </div>
          <div v-else>
            <video
              id="big-video"
              class="product-video"
              @ended="handleVideoEnd()"
            >
              <source :src="zoomImage">
            </video>
            <div
              v-if="play"
              class="play"
              @click="handlePlayPause('big-video')"
            />
            <div
              v-if="!play"
              class="pause"
              @click="handlePlayPause('big-video')"
            />
          </div>
        </b-col>
        <b-col lg="4">
          <div>
            <h1 class="font-weight-bold mb-4">
              {{ productName }}
            </h1>
            <h4 class="font-weight-bold">
              ${{ productPrice !== "" ? parseFloat(productPrice).toFixed(2) : '0.00' }}
            </h4>
            <p :class="['mb-0 font-weight-light', showMore ? '' : 'text-truncate']">
              {{ productDescription }}
            </p>
            <div class="text-right">
              <b-button
                v-if="productDescription.length > 45"
                class="font-weight-medium bg-transparent shadow-none"
                variant="default"
                @click="handleReadMore"
              >
                <span v-if="showMore">&lt; Read less</span>
                <span v-else>Read more &gt;</span>
              </b-button>
            </div>
            <p class="font-weight-light">
              This Item will ship in 7-10 business days
            </p>
            <b-button
              v-if="account_type === '1' || account_type === '2' && userId != productUserId"
              ref="button"
              variant="dark"
              class="btn-pop text-uppercase font-weight-bold mb-5 mt-3"
              style="width:70%"
              squared
              block
              @click="handlePurchase"
            >
              Buy now
            </b-button>
          </div>
        </b-col>
      </b-row>
      <div
        v-if="data.length > 0"
        class="pt-5"
      >
        <div class="font-weight-bold h4 border-bottom pt-4">
          <p class="header-title mb-0">
            Other listings by {{ fabName }}
          </p>
        </div>
        <VueSlickCarousel
          v-bind="settings"
          class="mt-4"
        >
          <b-card
            v-for="(image, index ) in data"
            :key="index"
            :class="[style['product-card'],'mb-4 text-center']"
            @click="handleRedirection(image)"
          >
            <b-img
              :class="[style['slider-image'],'w-100']"
              :src="image.product_images.length > 0 ? image.product_images[0].p_image : null "
              fluid
            />
            <p class="text-uppercase mt-4 text-left mb-0">
              {{ image.name }}
            </p>
            <p class="text-uppercase mb-0 text-left">
              ${{ image.price.toFixed(2) }}
            </p>
            <div :class="['view-btn', 'text-right']">
              <b-button
                pill
                variant="primary text-uppercase px-3"
                @click="handleRedirection(image)"
              >
                view
              </b-button>
            </div>
          </b-card>
        </VueSlickCarousel>
      </div>
    </div>
  </b-container>
</template>

<script>
import style from '../../styles/shopping.module.scss';
import VueSlickCarousel from 'vue-slick-carousel';
import LoadingSpinner from '../Common/LoadingSpinner';
import { getProductDetails, getRemainingProducts, addToCart } from '../../api/shopping';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
import placeholderImage from '../../assets/images/img-prod-placeholder.png';

export default {
    name:'ProductDetails',
    components:{ VueSlickCarousel, LoadingSpinner},
    data(){
        return {
            style,
            loading:false,
            showMore:false,
            username:localStorage.getItem('fabUsername'),
            userId:localStorage.getItem('userId'),
            fabId: localStorage.getItem('fabId'),
            productName:"",
            productPrice:"",
            productDescription:"",
            productId:"",
            productUserId:"",
            account_type: localStorage.getItem('account-type'),
            fabName: localStorage.getItem('fabName'),
            window: {
                width: 0,
                height: 0
            },
            settings:{
                "dots": false,
                "infinite": true,
                "speed": 500,
                "slidesToShow":4,
                "slidesToScroll": 4,
                "initialSlide": 0,
                "responsive": [
                    {
                        "breakpoint": 1024,
                        "settings": {
                            "slidesToShow": 3,
                            "slidesToScroll": 3,
                            "infinite": true,
                        }
                    },
                    {
                        "breakpoint": 768,
                        "settings": {
                            "slidesToShow": 2,
                            "slidesToScroll": 2,
                            "initialSlide": 2,
                            "arrows": false,
                        }
                    },
                    {
                        "breakpoint": 576,
                        "settings": {
                            "slidesToShow": 1,
                            "slidesToScroll": 1,
                            "arrows":false
                        }
                    }
                ],
            },
            images:[],
            data:[],
            showBuyButton: true,
            zoomImage: "",
            zoomType: 'image',
            play: true,
            placeholderImage,
            isVideoError: false
        };
    },
    async created(){
        this.loading = true;
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
        let id = this.$route.params.id;
        //Get product Details
        let details = await getProductDetails(id);
        let list = await getRemainingProducts(this.fabId, id);
        let newList = [];
        if (list.data.length > 0){
            this.loading = false;
            list.data.map(value => {
                if (value.status !== 3){
                    newList.push(value);
                }
            });
            this.data = newList;
        }
        if (document.getElementById('newsFeed') !== null){
            document.getElementById('newsFeed').firstChild.classList.remove('router-link-active');
        }
        if (details && Object.keys(details).length > 0){
            this.loading = false;
            this.productName = details.name;
            this.productPrice = details.price;
            this.productDescription = details.description;
            this.productId = details.id;
            this.productUserId = details.user_id;
            let productInCart = JSON.parse(localStorage.getItem('productsList'));

            productInCart !== null ? productInCart.map(value => {
                if (value.productId === details.id){
                    this.showBuyButton = false;
                }
            }) : (this.showBuyButton = true);
            this.zoomImage = details.product_images.length > 0 ? details.product_images[0].p_image : "";
            this.zoomType = details.product_images.length > 0 ? details.product_images[0].file_type : "";
            details.product_images.map(value => {
                this.images.push({id:value.id, file_type:value.file_type, image:value.p_image});
            });
        }
    },
    destroyed() {
        window.removeEventListener("resize", this.handleResize);
    },
    methods:{
        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },
        handleSelection(value, type){
            this.zoomType = type;
            this.zoomImage = document.getElementById(value).src;
        },
        handleRedirection(product){
            window.location.href = `/product/${product.id}`;
        },
        async handlePurchase(){
            let add = await addToCart(this.productId, this.productPrice);
            if (Object.keys(add).length > 0){
                this.$router.push('/cart');
            }
        },
        handleReadMore(){
            this.showMore = !this.showMore;
        },
        handlePlayPause(){
            let myVideo = document.getElementById("big-video");
            if (myVideo.paused) {
                myVideo.play();
                this.play = false;
            } else {
                myVideo.pause();
                this.play = true;
            }
        },
        handleVideoEnd(){
            this.play = true;
        },
        handleError(id){
            document.getElementById(id).src = placeholderImage;
        },
        handleVideoError(id){
            this.play = false;
            document.getElementById(id).src = placeholderImage;
            this.isVideoError = true;
        }
    }
};
</script>
